import React from "react";
import "./style.scss";
import { Col, Container, Row } from "react-bootstrap";
import logoLight from "../../assets/images/logoWhite.png";
import googleWhite from "../../assets/images/googleLight.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import fb from "../../assets/images/fb.png";
import github from "../../assets/images/github.png";
import vector from "../../assets/images/vector.png";
import dribbble from "../../assets/images/dribbble.png";

const ContactUs = () => {
  return (
    <div id="contact" className="contactUsWrapper">
      <Container>
        <Row className="contactDetailsWrapper">
          <Col lg={6} sm={12}>
            <Row>
              <Col lg={12} className="logoWrapper">
                <img width="105px" src={logoLight} alt="logoWhite" />
              </Col>
            </Row>
            <Row>
              <Col lg={6} style={{ color: "#fff", marginTop: "16px " }}>
                <p>
                  <b>Communication Address:</b> <br />
                  T-Hub, Plot No 1/C, Sy No 83/1, Raidurgam,
                  Knowledge City Rd, panmaktha, Hyderabad, Serilingampalle (M),
                  Telangana 500081 <br />
                  Phone 9030428730
                </p>
              </Col>
              <Col lg={12} className="hyperlinks">
                <a href="#productsNservices">
                  <p>Products</p>
                </a>
                <a href="#productsNservices">
                  <p>Services</p>
                </a>
                <a
                  href="https://easefarming.zohocommerce.in/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Terms & Conditions</p>
                </a>
                <a
                  href="https://easefarming.zohocommerce.in/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>Privacy</p>
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg={6} sm={12} className="googleIconWrapper">
            <p>Get the App</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.easefarming.agri&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <img width="150px" src={googleWhite} alt="googleWhite" />
            </a>
          </Col>
        </Row>

        <footer>
          <p>© 2023 easefarming. All rights reserved.</p>
          <div className="socialWrapper">
            <a
              href="https://instagram.com/easefarming?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/easefarming/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100092539075277"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} alt="fb" />
            </a>
            <a
              href="https://instagram.com/easefarming?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
              rel="noreferrer"
            >
              <img src={github} alt="instagram" />
            </a>
            <a
              href="https://instagram.com/easefarming?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
              rel="noreferrer"
            >
              <img src={vector} alt="vector" />
            </a>
            <a
              href="https://instagram.com/easefarming?igshid=NTc4MTIwNjQ2YQ=="
              target="_blank"
              rel="noreferrer"
            >
              <img src={dribbble} alt="dribbble" />
            </a>
          </div>
        </footer>
      </Container>
    </div>
  );
};

export default ContactUs;
